export const environment = {
    production: false,
    country: 'PY',
    APIEndpoint: 'https://apigatewaydemo.larifamos.com',
    translateUrl: "https://demo.larifamos.com",
    baseUrl: "https://demo.larifamos.com",
    domain: 'larifamos.com',
    protocol: 'https',
    appName:"laRifamos",
    raffleSimulatorEnable: false,
    oauthSocialEndpoint: 'https://socialoauth2.larifamos.com.py/oauth2/authorize',
    socialAuthRedirectUri: 'https://demo.larifamos.com/auth',
    subscription: { publicKey : 'BMqgs9tsmREgeOPXbFESrnJfkmEQWG3fHy9E_DMY0NcfK9F6zpmOVoZGmyOoEUwrpThtNcEvEHbchCd5d5lZ2MI'},
    mercadoPago : undefined,
    userPaymentModesFeatureEnable: true,
    twitter: undefined,
    facebook: 'https://www.facebook.com/LaRifamosPY',
    instagram: 'https://www.instagram.com/larifamos_py',
    youtube: 'https://www.youtube.com/@larifamos_py'
  };